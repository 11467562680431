import React from "react"
import styled from "styled-components"
import breakpoints from "@constants/breakpoints"
import { h2Styles, h4Styles } from "@constants/typography"
import Layout from "@components/Layout"
import Helmet from "@components/Helmet"

const Container = styled.main`
  padding: 88px 20px 0;

  @media ${breakpoints.laptop} {
    padding: calc(1 / 12 * 100vw) calc(1 / 12 * 100vw) calc(2 / 12 * 100vw);
  }
`

const Header = styled.h2`
  ${h4Styles}

  @media ${breakpoints.desktop} {
    ${h2Styles}
  }
`

const NotFoundPage = () => (
  <Layout>
    <Helmet title="404: Not found" />
    <Container>
      <Header>Not Found</Header>
    </Container>
  </Layout>
)

export default NotFoundPage
